.contact{
    display: flex;
    padding: 2.5% 10%;
    justify-content: space-evenly;
}
.contact1{
padding-right: 5%;
display: flex;
flex-direction: column;
text-align: justify;
}
.contact1a{
    padding-bottom: 1.5%;
}
.contact1ah3{
    font-weight: bold;
    color: rgb(78, 65, 59);
}
.contact1bh6{
    line-height: 1.5 !important;
    font-size: 1.25rem;
}
.contacticon{
    padding-right: 3%;
    padding-top: 1.5%;
    height: 1.25em;
}
.contact1b{
    color: rgb(78, 65, 59);
}
.contact1bb{
    display: flex;
}
@media (max-width:900px) {
    .contact{
        flex-direction: column;
        align-items: center
    }
    .contact1{
        padding-right: 0;
    }
    .contact1bh6{
        font-size: 1em !important;
    }
    .contact1ah3{
        text-align: center;
    }
    .contact1b{
        align-items: left ;
        display: flex;
        flex-direction: column;
        text-align: left;
    }
    .emailbutton{
        background-color: #CE7777 !important;
        border-color: #CE7777 !important;
        color: #FFEEEE !important;
        width: 100%;
        padding: 2.5% 34% !important;
        text-align: center;
        margin-left: 0 !important;
        
    }
    .contact2{
        width: 100%;
    }
    .contact2 iframe{
        display: none;
    }
    .contact1bh6{
        padding-bottom: 5% !important;
    }
}