.feedback {
    padding: 2.5% 10% 5%;
    align-items: center;
    flex-direction: column;
    display: flex;
    background-color: #FAF4F4;
}

.carousel-control-next,
.carousel-control-prev {
    width: auto;
}

.testimonial {
    padding: 0 3.5%;
    text-align: center;
    font-size: 1.25rem !important;
}
.testimonial1{
    font-size: 1.25rem !important;
}

.carousel-indicators {
    margin-top: 2rem;
    margin-bottom: 0;
}

.feedback1 {
    background-color: #FFF9F9;
    border-radius: 8px;
    border: 2px solid #FFC6D3;
    box-shadow: 0 0 25px #FFC6D3;

}

.item {
    height: 400px;
    padding-top: 2.5%;


}

.carousel-dark .carousel-caption {
    color: #4E413B;
}

.carousel-control-next,
.carousel-control-prev {
    display: none;
}
.test_image{
    height: 90px ;
    width: 90px;
    border-radius: 50px;
    margin-bottom: 1rem;
}
.testimonial1{
    line-height: 1.75;
}

@media (max-width:900px) {
    .feedback1 {
        height: 700px;
        width: 100%;
    }
    .testimonial1{
        line-height: normal;
    }

    .item {
        height: 680px;
        width: 100%;
    }

    .carousel-inner {
        overflow: hidden;
    }

    .testimonial {
        padding: 7.5% 7.5% 0;
        font-size: 1rem !important;
    }

    .feedback {
        padding-left: 7.5%;
        padding-right: 7.5%;
        padding-top: 5%;
    }

    .feedbackh4 {
        font-size: 1rem;
        text-align: center;
        padding-bottom: 7.5% !important;
    }
    .test_image{
        margin-top: 1rem;
    }
}