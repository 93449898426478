.front{
    display: flex;
    margin: 2.5% 7.5%;
    padding: 2.5%;
    justify-content: space-evenly;
    background-color:#FAF4F4;
    border-radius: 15px;
    border: 1.5px black solid;
    transform: rotateX(0deg);
    backface-visibility: hidden;
    height: 450px;
    width: 85%;
    
}
.front img{
    width: 100%;
    height: 100%;
    
}
.front1b{
    margin-left: 3.5rem;
    width: 50%;
}
.front1bh3{
    padding-bottom:1%;
    color:#CE7777;
    font-weight:bold;
}
.front1a{
    width: 50%;
}
.back{
    display: flex;
    margin: 2.5% 7.5%;
    transform: rotateX(0deg);
    padding: 2.5%;
    background-color:#FAF4F4;
    border-radius: 15px;
    border: 1.5px black solid;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    height: 450px;
    width: 85%;
}
.back img{
    width: 100%;
}
.adult{
    display: flex;
    transform-style: preserve-3d;
    position: relative;
    
}
.front1abutton{
    display: none;
}
.back1{
    text-align: justify;
}

@media (max-width:900px) {
    .adult{
        margin-top: 5%;
        margin-bottom: 5%;
        padding-bottom: 5%;
    }
    .front{
        flex-direction: column-reverse;
        height: 1000px;
        padding: 3% 7.5%;
    }
    .front1b{
        margin-left: 0;
        width: 100%;
    }
    .front1bh3{
        text-align: center;
    }
    .front1bh5{
        font-size: 1em !important;
    }

    .front1a{
        width: 100%;
    }
    .back{
        flex-direction: column;
        height: 1000px;
        padding: 3% 7.5%;
    }
    .back1{
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .front1abutton{
        padding: 3% !important;
        background-color: #CE7777 !important;
        border-color: #CE7777 !important;
        color: #FFEEEE !important;
        width: 100%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
    }
    .front1abutton{
        display: flex !important;
    }
}