.header{
    width: 100%;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3.5%;
    padding-bottom: 3.5%;
    align-items: center;
    justify-content: space-between;
    background-color: #FAF4F4;
}
.first{
    width: 50%;
}
.second{
    width: 50%;
    
    align-content: end;

    }
.image1{
    width: 125%;
}
.mobile{
    display: none;
}
@media (max-width:900px) {
    .header{
        flex-direction: column;
        align-items: center;
        padding-left: 7.5%;
        padding-right: 7.5%;
    }
    .first{
        width: 90%;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .desktop{
        display: none;
    }
    .mobile{
        visibility: visible;
    }
    .second {
        flex-direction: column;
        display: flex;
        width: 90%;
        align-items: center;
    }
    .header h5{
        font-size: 0.83em;
    }
    .header h1{
        font-size: 1.5em;
    }
}