.section2{
   display: flex;
   padding: 2.5% 10%;
   justify-content: space-evenly;
}
.section1{
    text-align: center;
    padding-top: 2rem;
}
.section4{
    display: flex;
    padding: 1.5% 1.5%;
    justify-content: space-evenly;
}
.section3{
    text-align: center;
    background-color: #FAF4F4;
}
.section2a{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    margin-right: 10%;

}
.section5{
    display: none;
}
@media (max-width:900px){

    .section4{
        flex-direction: column;
        align-items: center;
    }
    .card{
        padding-top: 5%;
        padding-bottom: 5%;
    }
    .section3{
        padding-top: 5%;
    }
    .section2{
        flex-direction: column;
        align-items: center;
        padding-left: 7.5%;
        padding-right: 7.5%;
        padding-top: 0;
        
    }
    .section2a{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0;
    }
    .section2 h5{
        font-size: 0.83em;
        padding-bottom: 5%;
        text-align: center !important;
    }
    .section1{
        padding-left: 7.5%;
        padding-right: 7.5%;
        padding-top: 1em;
    }
    .section5{
            align-items: center;
            display: flex;
            padding-bottom: 7.5%;
    }
    .section4{
        display: none;
    }
    .carousel {
        position: relative;
        height: 450px;
    }
    .carousel-inner{
        overflow: visible;
    }
    .card{
        width:100% !important
    }
    .section5 a {
        display: flex !important;
    }
    .section2 h3{
        padding-bottom: 0 !important;
    }

}