.footer{
    background-color: #F4F4F4;
    min-height: 120px;
    padding-top: 1.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.brand{
    padding: 0 1rem;
    height: 2em;
}
.para{
    padding-top: 5% !important;
    color: rgb(78, 65, 59);
    font-weight: bold;
}