.menu1:hover{
    color: #C89595 !important;

}
.logo{
    height: 80px;
}
.navbar-expand-lg .navbar-nav .nav-link{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}
.px-6{
    font-size: large !important;
}

.dropdown-menu{
    background-color: #CE7777 !important;
    
}
.dropdown-item{
    color: #FFEEEE !important;
    --bs-dropdown-link-hover-bg: #C89595 !important;
}

#basic-nav-dropdown {
    color: #CE7777 !important;
}
.btn-primary{
    --bs-btn-active-border-color:#CE7777;
}
.navbar-collapse{
    background-color: #FFEEEE;
}
.container{
    padding-left: 4% !important;
}

@media (max-width:900px) {
    .container{
        padding: 0 !important;
        padding-left: 7.5% !important;
    }
    .menu1{
        font-size: medium !important;
    }
    .navbar-toggler:focus{
        box-shadow: none !important;

    }
    .navbar-toggler{
        margin-right: 1.5rem !important;
    }
    .navbar-brand{
        padding-left: 1.5rem;
    }
    .px-6{
        font-size: medium !important;
        margin-left: 1.5rem;
        margin-bottom: 1rem;
        margin-top: 0.75rem;
        border: 0 !important;
        background-color: #FFEEEE !important;
        color: #CE7777 !important;
        font-weight: bold !important;
        padding: 0 !important;
    }
    .logo{
        height: 50px;
    }

}