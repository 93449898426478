body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu1:hover{
    color: #C89595 !important;

}
.logo{
    height: 80px;
}
.navbar-expand-lg .navbar-nav .nav-link{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}
.px-6{
    font-size: large !important;
}

.dropdown-menu{
    background-color: #CE7777 !important;
    
}
.dropdown-item{
    color: #FFEEEE !important;
    --bs-dropdown-link-hover-bg: #C89595 !important;
}

#basic-nav-dropdown {
    color: #CE7777 !important;
}
.btn-primary{
    --bs-btn-active-border-color:#CE7777;
}
.navbar-collapse{
    background-color: #FFEEEE;
}
.container{
    padding-left: 4% !important;
}

@media (max-width:900px) {
    .container{
        padding: 0 !important;
        padding-left: 7.5% !important;
    }
    .menu1{
        font-size: medium !important;
    }
    .navbar-toggler:focus{
        box-shadow: none !important;

    }
    .navbar-toggler{
        margin-right: 1.5rem !important;
    }
    .navbar-brand{
        padding-left: 1.5rem;
    }
    .px-6{
        font-size: medium !important;
        margin-left: 1.5rem;
        margin-bottom: 1rem;
        margin-top: 0.75rem;
        border: 0 !important;
        background-color: #FFEEEE !important;
        color: #CE7777 !important;
        font-weight: bold !important;
        padding: 0 !important;
    }
    .logo{
        height: 50px;
    }

}
.header{
    width: 100%;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3.5%;
    padding-bottom: 3.5%;
    align-items: center;
    justify-content: space-between;
    background-color: #FAF4F4;
}
.first{
    width: 50%;
}
.second{
    width: 50%;
    
    align-content: end;

    }
.image1{
    width: 125%;
}
.mobile{
    display: none;
}
@media (max-width:900px) {
    .header{
        flex-direction: column;
        align-items: center;
        padding-left: 7.5%;
        padding-right: 7.5%;
    }
    .first{
        width: 90%;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .desktop{
        display: none;
    }
    .mobile{
        visibility: visible;
    }
    .second {
        flex-direction: column;
        display: flex;
        width: 90%;
        align-items: center;
    }
    .header h5{
        font-size: 0.83em;
    }
    .header h1{
        font-size: 1.5em;
    }
}
.section2{
   display: flex;
   padding: 2.5% 10%;
   justify-content: space-evenly;
}
.section1{
    text-align: center;
    padding-top: 2rem;
}
.section4{
    display: flex;
    padding: 1.5% 1.5%;
    justify-content: space-evenly;
}
.section3{
    text-align: center;
    background-color: #FAF4F4;
}
.section2a{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    margin-right: 10%;

}
.section5{
    display: none;
}
@media (max-width:900px){

    .section4{
        flex-direction: column;
        align-items: center;
    }
    .card{
        padding-top: 5%;
        padding-bottom: 5%;
    }
    .section3{
        padding-top: 5%;
    }
    .section2{
        flex-direction: column;
        align-items: center;
        padding-left: 7.5%;
        padding-right: 7.5%;
        padding-top: 0;
        
    }
    .section2a{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0;
    }
    .section2 h5{
        font-size: 0.83em;
        padding-bottom: 5%;
        text-align: center !important;
    }
    .section1{
        padding-left: 7.5%;
        padding-right: 7.5%;
        padding-top: 1em;
    }
    .section5{
            align-items: center;
            display: flex;
            padding-bottom: 7.5%;
    }
    .section4{
        display: none;
    }
    .carousel {
        position: relative;
        height: 450px;
    }
    .carousel-inner{
        overflow: visible;
    }
    .card{
        width:100% !important
    }
    .section5 a {
        display: flex !important;
    }
    .section2 h3{
        padding-bottom: 0 !important;
    }

}
@media (max-width:900px) {
    #services{
        padding-top: 7.5% !important;
    }
}

.front{
    display: flex;
    margin: 2.5% 7.5%;
    padding: 2.5%;
    justify-content: space-evenly;
    background-color:#FAF4F4;
    border-radius: 15px;
    border: 1.5px black solid;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    height: 450px;
    width: 85%;
    
}
.front img{
    width: 100%;
    height: 100%;
    
}
.front1b{
    margin-left: 3.5rem;
    width: 50%;
}
.front1bh3{
    padding-bottom:1%;
    color:#CE7777;
    font-weight:bold;
}
.front1a{
    width: 50%;
}
.back{
    display: flex;
    margin: 2.5% 7.5%;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    padding: 2.5%;
    background-color:#FAF4F4;
    border-radius: 15px;
    border: 1.5px black solid;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    height: 450px;
    width: 85%;
}
.back img{
    width: 100%;
}
.adult{
    display: flex;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative;
    
}
.front1abutton{
    display: none;
}
.back1{
    text-align: justify;
}

@media (max-width:900px) {
    .adult{
        margin-top: 5%;
        margin-bottom: 5%;
        padding-bottom: 5%;
    }
    .front{
        flex-direction: column-reverse;
        height: 1000px;
        padding: 3% 7.5%;
    }
    .front1b{
        margin-left: 0;
        width: 100%;
    }
    .front1bh3{
        text-align: center;
    }
    .front1bh5{
        font-size: 1em !important;
    }

    .front1a{
        width: 100%;
    }
    .back{
        flex-direction: column;
        height: 1000px;
        padding: 3% 7.5%;
    }
    .back1{
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .front1abutton{
        padding: 3% !important;
        background-color: #CE7777 !important;
        border-color: #CE7777 !important;
        color: #FFEEEE !important;
        width: 100%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
    }
    .front1abutton{
        display: flex !important;
    }
}
.flip-exit {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
}

.flip-exit-active {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    transition-timing-function: cubic-bezier(.57,.21,.69,1.25);
}

.flip-exit-done {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.flip-enter {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.flip-enter-active {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    transition-timing-function: cubic-bezier(.57,.21,.69,1.25);
}

.flip-enter-done {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
}
.fronta{
    display: flex;
    margin: 2.5% 7.5%;
    padding: 2.5%;
    justify-content: space-evenly;
    background-color:#FAF4F4;
    border-radius: 15px;

    border: 1.5px black solid;
}
.frontbutton{
    position: absolute;
    bottom: 7.5%;
}

.backa{
    display: flex;
    margin: 2.5% 7.5%;
    padding: 2.5%;
    background-color:#FAF4F4;
    border-radius: 15px;
    border: 1.5px black solid;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    
}
.front{
    display: flex;
    margin: 2.5% 7.5%;
    padding: 2.5%;
    justify-content: space-evenly;
    background-color:#FAF4F4;
    border-radius: 15px;
    border: 1.5px black solid;
    
}
.front img{
    width: 100%;
}
.front1b{
    margin-left: 3.5rem;
}
.front1bh3{
    padding-bottom:1%;
    color:#CE7777;
    font-weight:bold;
}
.back{
    display: flex;
    margin: 2.5% 7.5%;
    padding: 2.5%;
    background-color:#FAF4F4;
    border-radius: 15px;
    border: 1.5px black solid;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}
.back img{
    width: 100%;
}
.back1b{
    width: 50%;
}
.back1a{
    width: 50%;
}
.adult{
    display: flex;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative;
    
}
.front1abutton{
    display: none !important;
}
@media (max-width:900px) {
    .adult{
        margin-top: 5%;
        margin-bottom: 5%;
        padding-bottom: 5%;
    }
    .front{
        height: 1000px;
        padding: 3% 7.5%;
    }
    .front1b{
        margin-left: 0;
    }
    .front1bh3{
        text-align: center;
    }
    .back1b{
        width: 100%;
    }
    .back1a{
        width: 100%;
    }
    .frontab{
        flex-direction: column !important;
    }
    .backa{
        flex-direction: column-reverse !important;
    }
    .front1bh5{
        font-size: 1em !important;
    }
    .frontbutton{
        display: none !important;

    }
    .front1abutton{
        display: flex !important;
    }
    .back{
        flex-direction: column;
        height: 1000px;
        padding: 3% 7.5%;
    }
    .back1b{
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .front1abutton{
        padding: 3% !important;
        background-color: #CE7777 !important;
        border-color: #CE7777 !important;
        color: #FFEEEE !important;
        width: 100%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
    }
}


.feedback {
    padding: 2.5% 10% 5%;
    align-items: center;
    flex-direction: column;
    display: flex;
    background-color: #FAF4F4;
}

.carousel-control-next,
.carousel-control-prev {
    width: auto;
}

.testimonial {
    padding: 0 3.5%;
    text-align: center;
    font-size: 1.25rem !important;
}
.testimonial1{
    font-size: 1.25rem !important;
}

.carousel-indicators {
    margin-top: 2rem;
    margin-bottom: 0;
}

.feedback1 {
    background-color: #FFF9F9;
    border-radius: 8px;
    border: 2px solid #FFC6D3;
    box-shadow: 0 0 25px #FFC6D3;

}

.item {
    height: 400px;
    padding-top: 2.5%;


}

.carousel-dark .carousel-caption {
    color: #4E413B;
}

.carousel-control-next,
.carousel-control-prev {
    display: none;
}
.test_image{
    height: 90px ;
    width: 90px;
    border-radius: 50px;
    margin-bottom: 1rem;
}
.testimonial1{
    line-height: 1.75;
}

@media (max-width:900px) {
    .feedback1 {
        height: 700px;
        width: 100%;
    }
    .testimonial1{
        line-height: normal;
    }

    .item {
        height: 680px;
        width: 100%;
    }

    .carousel-inner {
        overflow: hidden;
    }

    .testimonial {
        padding: 7.5% 7.5% 0;
        font-size: 1rem !important;
    }

    .feedback {
        padding-left: 7.5%;
        padding-right: 7.5%;
        padding-top: 5%;
    }

    .feedbackh4 {
        font-size: 1rem;
        text-align: center;
        padding-bottom: 7.5% !important;
    }
    .test_image{
        margin-top: 1rem;
    }
}
.contact{
    display: flex;
    padding: 2.5% 10%;
    justify-content: space-evenly;
}
.contact1{
padding-right: 5%;
display: flex;
flex-direction: column;
text-align: justify;
}
.contact1a{
    padding-bottom: 1.5%;
}
.contact1ah3{
    font-weight: bold;
    color: rgb(78, 65, 59);
}
.contact1bh6{
    line-height: 1.5 !important;
    font-size: 1.25rem;
}
.contacticon{
    padding-right: 3%;
    padding-top: 1.5%;
    height: 1.25em;
}
.contact1b{
    color: rgb(78, 65, 59);
}
.contact1bb{
    display: flex;
}
@media (max-width:900px) {
    .contact{
        flex-direction: column;
        align-items: center
    }
    .contact1{
        padding-right: 0;
    }
    .contact1bh6{
        font-size: 1em !important;
    }
    .contact1ah3{
        text-align: center;
    }
    .contact1b{
        align-items: left ;
        display: flex;
        flex-direction: column;
        text-align: left;
    }
    .emailbutton{
        background-color: #CE7777 !important;
        border-color: #CE7777 !important;
        color: #FFEEEE !important;
        width: 100%;
        padding: 2.5% 34% !important;
        text-align: center;
        margin-left: 0 !important;
        
    }
    .contact2{
        width: 100%;
    }
    .contact2 iframe{
        display: none;
    }
    .contact1bh6{
        padding-bottom: 5% !important;
    }
}
.footer{
    background-color: #F4F4F4;
    min-height: 120px;
    padding-top: 1.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.brand{
    padding: 0 1rem;
    height: 2em;
}
.para{
    padding-top: 5% !important;
    color: rgb(78, 65, 59);
    font-weight: bold;
}
@media (max-width:900px) {
    .scroll{
        display: none !important;
    }
}
